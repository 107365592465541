export default class Decode {
    _keyStr: string;
  
    /**
     * Constructor
     */
    constructor() {
      this._keyStr =
        '484244524b4143454647494a4c4d4e4f5051535455565758595a686264726b6163656667696a6c6d6e6f7071737475767778797a393837363534333231302b2f3d';
      this._keyStr = this.hta(this._keyStr);
    }
  
    /**
     * Hexa to ascii conversion
     * @param str1
     * @returns {string}
     * @override
     */
    hta(str1: string) {
      let hex = str1.toString();
      let str = '';
      for (let n = 0; n < hex.length; n += 2) {
        str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
      }
      return str;
    }
  
    /**
     * encode
     * @param input
     * @returns {string}
     */
    decode(input: string) {
      let output = '';
      let chr1, chr2, chr3;
      let enc1, enc2, enc3, enc4;
      let i = 0;
      input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');
  
      while (i < input.length) {
        enc1 = this._keyStr.indexOf(input.charAt(i++));
        enc2 = this._keyStr.indexOf(input.charAt(i++));
        enc3 = this._keyStr.indexOf(input.charAt(i++));
        enc4 = this._keyStr.indexOf(input.charAt(i++));
        chr1 = (enc1 << 2) | (enc2 >> 4);
        chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
        chr3 = ((enc3 & 3) << 6) | enc4;
        output = output + String.fromCharCode(chr1);
  
        if (enc3 !== 64) {
          output = output + String.fromCharCode(chr2);
        }
  
        if (enc4 !== 64) {
          output = output + String.fromCharCode(chr3);
        }
      }
  
      output = this._utf8_decode(output);
  
      return output;
    }
  
    /**
     * utf8 decode string
     * @param utftext
     * @returns {string}
     * @private
     */
    _utf8_decode(utftext: string) {
      let string = '';
      let i = 0;
      let c = 0,
        c3 = 0,
        c2 = 0;
  
      while (i < utftext.length) {
        c = utftext.charCodeAt(i);
  
        if (c < 128) {
          string += String.fromCharCode(c);
          i++;
        } else if (c > 191 && c < 224) {
          c2 = utftext.charCodeAt(i + 1);
          string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
          i += 2;
        } else {
          c2 = utftext.charCodeAt(i + 1);
          c3 = utftext.charCodeAt(i + 2);
          string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
          i += 3;
        }
      }
      return string;
    }
  }